<template>
    <div id="container">
        <div id="background">
            <div class="app flex-row align-items-center" id="particles-js"/>
        </div>
        <div class="container-fluid" style="z-index:2">
            <b-card style="margin-top: 10px">
                <h1 style="text-align: center; font-size: 50px; line-height: 15px"><strong>Flight Information</strong>
                </h1>
            </b-card>
            <div v-for="flight in flights" :key="flight.id"
                 v-if="flight.boarder === 'info' || flight.boarder === 'success' || flight.boarder === 'warning'">
                <b-card :border-variant="flight.boarder">
                      <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                            <img :src="mapAirlineLogo(flight.airline)" style="max-height:170px; max-width: 300px " alt=""/>
                            <div style="display: flex; flex-direction: column; align-items: center; line-height: 10px">
                              <h1 style="font-size: 70px">Flight:<b> {{ flight.flight }}</b></h1>
                              <h1 style="font-size: 70px; line-height: 5vh">Gate:<b> {{ flight.gate }}</b></h1>
                            </div>
                            <div style="display: flex; flex-direction: column; align-items: center">
                              <h1 style="font-size: 60px">Status:</h1>
                              <h1 style="font-size: 60px; line-height: 5vh"><b>{{ flight.status }}</b></h1>
                            </div>
                            <div>
                              <h1 style="font-size: 60px">Departing: <b>{{ flight.departing }} {{ (flight.departuretime).slice((flight.departuretime).indexOf(" ")) }}</b></h1>
                              <h1 style="font-size: 60px; line-height: 5vh">Arriving: <b>{{ flight.arriving }} {{ (flight.arrivaltime).slice((flight.arrivaltime).indexOf(" ")) }}</b></h1>
                            </div>
                      </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../node_modules/particles.js/particles';
import fcr from "@/services/fcr";
import blackhole from "@/services/blackhole";

export default {
    name: "fids_overview",
    data() {
        return {
            logoId: '',
            flights: [],
            airlines: [],

        }
    },
    created() {
        fcr.readAirlines().then(response => {
            this.airlines = response.data;
        }, error => {
            console.error(error);
        });

        setInterval(() => {
            let currentTime = new Date().getTime();
            fcr.readFlights().then(result => {
                for (let i = result.data.length - 1; i >= 0; i--) {
                    if (!result.data[i].rollIn || currentTime < parseInt(result.data[i].rollIn) || currentTime >= parseInt(result.data[i].rollOut)) {
                        result.data.splice(i, 1);
                    }
                }
                this.flights = result.data;
            }, error => {
                console.error(error);
            });
        }, 3000)
    },
    methods: {
        mapAirlineLogo(airlineName) {
            for (let airline of this.airlines) {
                if (airline.name === airlineName) {
                    return this.logo(airline.image);
                }
            }
        },
        logo(logoId) {
            if (!logoId) {
                return ""
            } else {
                return blackhole.readImage(logoId);
            }
        },
        initParticles() {
            window.particlesJS("particles-js", {
                "particles": {
                    "number": {
                        "value": 80,
                        "density": {
                            "enable": true,
                            "value_area": 700
                        }
                    },
                    "color": {
                        "value": "#ffffff"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 5,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 6,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "grab"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 140,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            })
        },
    },

    computed: {},

    mounted() {
        this.initParticles();
    },
}
</script>

<style scoped>

#particles-js {
    background: #2f353a;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
}

canvas {
    position: absolute;
    z-index: 0;
}

#container {
    position: relative;
}

</style>